@import "normalize", "meyer", "basevalues";

.selected-projects {
    padding: 2em 0;

    .selected-projects-title {
        display: block;
        width: 100%;
        // max-width: 490px;
        margin: 0 auto;

        font-size: 2.3em;
        // font-family: "Oswald", sans-serif;
        line-height: 1.2em;
        text-transform: uppercase;
        text-align: center;
        color: $colorText;
    }

    .selected-projects-subtitle {
        display: block;
        width: 85%;
        max-width: 490px;
        margin: 0 auto;
        // padding: 0 0 1.5em;
        padding: 1em 0 0;

        font-size: 1em;
        line-height: 1.2em;
        text-align: center;
        text-indent: 3%;
        color: $colorTextAlt;
    }

    .btn-view-allpro {
        display: block;
        width: 170px;
        margin: 80px auto;
        padding: 0.6em 0;

        color: $light;
        border: 2px solid $light;
        border-radius: 8px;
        text-decoration: none;

        p {
            font-size: 1em;
        }
    }

    // .btn-view-allpro-txt {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: center;
    //     width: 170px;
    //     height: 48px;
    //     position: relative;
    //     margin: 80px auto;
    //     padding: 0px 0px 0px 14px;
    //     // padding: 6px 0px;
    //     // padding: 12px 50px;
    //     transition: all .2s ease;
    //     text-decoration: none;
    //     color: $colorText;
    //     background: rgba($colorResalte, .5);
    //     border: 2px solid $colorText;
    //     border-radius: 10px;
    
    //     &:before {
    //         content: "";
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         display: block;
    //         border-radius: 8px;
    //         // background: rgba($colorResalte, .5);
    //         background: rgba($colorResalte, 1);
    //         width: 100%;
    //         // width: 78px;
    //         height: 48px;
    //         transition: all .3s ease;
    //     }

    //     .container-text {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //     }
    
    //     span {
    //         position: relative;
    //         font-size: 16px;
    //         line-height: 18px;
    //         // font-weight: 900;
    //         font-weight: 400;
    //         // text-transform: uppercase;
    //         vertical-align: middle;
    //     }
    
    //     svg {
    //         position: relative;
    //         top: 0;
    //         margin-left: 10px;
    //         fill: none;
    //         stroke-linecap: round;
    //         stroke-linejoin: round;
    //         stroke: $colorText;
    //         stroke-width: 2;
    //         // transform: translateX(-5px) rotate(0deg);
    //         transform: translateX(0) rotate(45deg);
    //         transition: all .3s ease;
    //     }
    
    //     &:hover {
    //         &:before {
    //             // width: 100%;
    //             // width: 58px;
    //             width: 0px;
    //             // background: rgba($colorResalte, 1);
    //             background: rgba($colorResalte, .5);
    //         }
    
    //         svg {
    //             transform: translateX(-5px) rotate(0deg);
    //             // transform: translateX(0) rotate(45deg);
    //         }
    //     }
    
    //     // &:active {
    //     //     transform: scale(.96);
    //     // }
    // }
}

.selected-projects-list {
    display: block;
    width: 100%;
    max-width: $maxWidth;

    .selected-project-item {
        display: block;
        width: 100%;
        margin: 30px 0 0;
        // padding: 10px;

        position: relative;
        // box-shadow: 0 0 10px rgba($light, 0.1);
        border-radius: 4px;
        overflow: hidden;
        
        img {
            max-width: 100%;
            height: auto;
            // border-radius: 10px;
        }

        a {
            display: block;
            // margin-top: 10px;
            // color: #3498db;
            text-decoration: none;
            font-weight: 600;
        }

        .project-side-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            padding: 1em 0;
            position: absolute;
            bottom: 0%;
            left: 50%;
            transform: translate(-50%, 0%);
            background: linear-gradient(rgba($black, 0), $black);

            h3, p, h5 {
                display: block;
                width: 90%;
                margin: 0 auto;
                text-align: left;
            }

            h3, p {
                text-shadow: 0 0 6px $black;
            }

            h3 {
                // font-family: "Oswald", sans-serif;
                font-optical-sizing: auto;
                font-weight: 400;
                font-style: normal;
                // font-size: 1.6em;
                font-size: 1.2em;
                color: $colorText;
            }
        
            p {
                display: none;
                font-size: 0.9em;
                font-weight: 300;
                line-height: 1.3em;
                padding: 1em 0 0;
                color: $colorTextAlt;
            }

            h5 {
                width: fit-content;
                // margin: 1em auto 0;
                margin: 1em 5% 0;
                padding: 1em 1em;
                border-radius: 100px;

                font-size: 0.7em;
                color: $colorTextAlt;

                background: rgba($gray, 0.5);
            }
        }
    }
}

@media (orientatioselected-n: landscape) {
    .projects-list {
        .selected-project-item {
            width: 65%;
        }
    }
}

@media (min-width: 768px) {
    .selected-projects {
        .selected-projects-title {
            font-size: 6em;
            padding: 0.3em 0 0.2em;
        }
        .btn-view-allpro {
            margin: 100px auto;            
        }
    }
    .selected-projects-list {
        max-width: 1024px;
        margin: 0 auto;
        
        .selected-project-item {
            width: 100%;

            border-radius: 20px;
            overflow: hidden;

            img {
                // border-radius: 20px;
                transition: .3s;
            }

            .project-side-container {
                padding: 1em 4em 2em;

                h3 {
                    font-size: 2em;
                    transition: .3s;
                    width: 70%;
                    margin: initial;
                }
                p {
                    display: block;
                    font-size: 1.2em;
                    line-height: 1.5em;
                    transition: .3s;
                    width: 70%;
                    margin: initial;
                }
                h5 {
                    font-size: .9em;
                    transition: .3s;
                    margin: 1em 0 0;
                }
            }

            &:hover {
                img {
                    opacity: .5;
                }

                .project-side-container {
                    h3 {
                        font-size: 2.5em;
                    }
                    p {
                        font-size: 1.4em;
                        line-height: 1.6em;
                    }
                    h5 {
                        // font-size: 1.2em;
                    }
                }
            }
        }
    }
}