@import "normalize", "meyer", "basevalues";

.about {
    width: 95%;
    // background-color: #f8f8f8;
    padding: 50px 0px;
    text-align: center;
    max-width: $maxWidth;
    margin: 0 auto;

    .about-pic {
        width: 100%;
        // width: 85%;
        height: fit-content;
        aspect-ratio: 291/248;
        margin: 1em 0;
    }

    .block-container {
        display: flex;
        flex-direction: column;

        h4 {
            width: 90%;
            margin: 0 auto;
            padding: 1.5em 0 1em;
            font-size: 1.4em;
            text-align: start;
            color: $colorText;
        }
    
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            width: 90%;
            margin: 0 auto;

            h5 {
                width: 100%;
                padding: 0 0 1em;

                color: $colorTextAlt;
                font-weight: 100;
                text-align: left;
                line-height: 1.4em;

                span {
                    font-weight: 400;
                }
            }

            p {
                padding: 0 0 1em;

                color: $colorTextGray;
                text-align: left;
                font-weight: 100;
                line-height: 1.4em;
            }
    
            li {
                display: flex;
                flex-direction: row;
                align-items: center;

                margin: 0 0 1em 2.5%;
                padding: 1em 1em;
    
                // cursor: pointer;
                // border-radius: 10px;
                font-size: .8em;
                color: $colorTextAlt;
                // background: rgba(90, 90, 90, .15);
                // border: 1px solid $colorTextAlt;
                transition: .3s;
    
                &:hover {
                    // background: rgba(90, 90, 90, .2);
                }

                .tool-item {
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin: 0 0 0 1em;
                    // background: red;
                    transition: .3s;
                }
            }
        }
    }

    .skills-container {
        ul li {
            border-radius: 10px;
            border-bottom: 1px solid $colorText;
        }
    }

    .tools-container {
        ul li{
            padding: 0.25em 0.5em;
            border-radius: 10px;
            border-bottom: 1px solid $colorResalte;
        }
    }

    .description-about {
        display: block;
        width: 85%;
        max-width: 490px;
        margin: 0 auto;
        padding: 1em 0 0;
        font-size: 1.3em;
        font-weight: 100;
        line-height: 1.2em;
        text-align: justify;
        text-indent: 3%;
        color: $colorTextAlt;
    }

    .cv-link-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 170px;
        height: 48px;
        position: relative;
        margin: 40px auto;
        padding: 0px 0px 0px 14px;
        // padding: 6px 0px;
        // padding: 12px 50px;
        transition: all .2s ease;
        text-decoration: none;
        color: $colorText;
        // background: rgba($colorResalte, .5);
        background: transparent;
        border: 2px solid $colorText;
        border-radius: 50px;

        // &:before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     display: block;
        //     border-radius: 50px;
        //     // background: rgba($colorResalte, .5);
        //     // background: rgba($colorResalte, 1);
        //     background: transparent;
        //     width: 100%;
        //     // width: 78px;
        //     height: 48px;
        //     transition: all .3s ease;
        // }

        .container-text {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        span {
            position: relative;
            font-size: 16px;
            line-height: 18px;
            // font-weight: 900;
            font-weight: 400;
            // text-transform: uppercase;
            vertical-align: middle;
        }

        svg {
            position: relative;
            top: 0;
            margin-left: 10px;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: $colorText;
            stroke-width: 2;
            // transform: translateX(-5px) rotate(0deg);
            transform: translateX(0) rotate(-45deg);
            transition: all .3s ease;
        }

        &:hover {
            &:before {
                // width: 100%;
                // width: 58px;
                width: 0px;
                // background: rgba($colorResalte, 1);
                background: rgba($colorResalte, .5);
            }

            svg {
                transform: translateX(0) rotate(0deg);
                // transform: translateX(0) rotate(45deg);
            }
        }
    }
}

// Config About Transition ===================

#SubtitleContainer, #AboutPic, #BlockExperience {
    opacity: 0;
    transition: 0.5s;
}

.about-activated-elem {
    opacity: 1 !important;
}

// ===========================================

.figma {
    background: url("../assets/tools/figma.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.framer {
    background: url("../assets/tools/Framer.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.adobe {
    background: url("../assets/tools/Adobe.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.html {
    background: url("../assets/tools/HTML.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.css {
    background: url("../assets/tools/CSS.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.javascript {
    background: url("../assets/tools/JS.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.react {
    background: url("../assets/tools/React.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.jira {
    background: url("../assets/tools/JIRA.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.notion {
    background: url("../assets/tools/Notion.png");
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .about {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 90%;
        // padding: 50px 20px;
        padding: $desktopMargin 20px 50px;

        .description-about {
            font-size: 1.2em;
            text-align: start;
        }

        .top-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .subtitle-container {
                // width: 40%;
                // margin: 0 10% 0 0;
                width: 50%;
                margin: initial;
            }
    
            .about-pic {
                width: 50%;
                border-radius: 8px;
            }

            .paragraph-container {
                width: 45%;
                margin: initial;
            }
        }

        .block-container {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;

            h4 {
                // width: 100%;
                width: 95%;
                // width: 35%;
                font-size: 1.5em;
                // padding: 1.2em 0 0.8em;
                padding: initial;
                margin: 2% auto 0;
            }
    
            ul {
                // width: 80%;
                // width: 65%;
                width: 45%;
                margin: 2% auto;
                // justify-content: flex-end;
    
                li {
                    font-size: 0.9em;
                    color: $colorTextAlt;
                    // background: rgba(90, 90, 90, .5);
    
                    // &:hover {
                    //     background: linear-gradient(25deg,rgba($colorText, .2),rgba($colorText, 0));
                    // }
                }
            }
        }

        .skills-container, .tools-container {
            width: 95%;
            margin: 0 auto;

            h4 {
                width: 35%;
            }
            ul {
                width: 65%;
                justify-content: flex-end;
            }
        }

        .tools-container {
            ul li {
                cursor: pointer;
                padding: 1em 1em;

                .tool-item {
                    // display: none;
                }

                &:hover {
                    // padding: 0.25em 0.5em;
                    background: linear-gradient(25deg, rgba($colorText, .2), rgba($colorText, 0));
                    
                    .tool-item {
                        // display: block;
                    }
                }
            }
        }

    }
}