@import "normalize", "meyer", "basevalues";

.footer-box {
    display: block;
    width: 100%;
    background-color: $colorResalte;
}

#footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;

    color: $colorFooterText;
    text-align: center;
    padding: 20px 0 10px;
    // position: fixed;
    // bottom: 0;
    width: 100%;
    margin: 0 auto;
}

.descriptive-container, .menu-footer-links, .copyright {
    width: 95%;
}

.descriptive-container {
    display: block;
    // width: 90%;

    .footer-pic {
        display: block;
        width: auto;
        height: 75px;
    }

    .explicative-text {
        display: block;
        padding: 1em 0;

        font-size: 1em;
        line-height: 1.5em;
        font-weight: 300;
        text-align: left;
        color: $colorFooterText;
    }
}

.menu-footer-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    // width: 90%;

    a {
        padding: 0.5em 0;
        color: $colorFooterLinks;
        transition: .3s;

        &:hover {
            color: $colorFooterLinksAlt;
        }
    }
}

.copyright {
    padding: 1.5em 0;
    text-align: left;
    color: $colorFooterText;
}

@media (min-width: 768px) {
    #footer-container {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 90%;
        padding: 20px 5%;
    }

    .descriptive-container{
        width: 40%;
    }

    .menu-footer-links {
        align-items: flex-end;
        width: 30%;
    }

    .copyright {
        width: 100%;
        text-align: center;
        padding: 1.2em 0 0;
    }
}