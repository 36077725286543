@import "normalize", "meyer", "basevalues";

#ProjectsContainer {
    display: block;
    opacity: 0;
    transition: .3s ease-in-out;
}

.projects-activated {
    opacity: 1 !important;
}

.projects {
    background-color: $black;
    // padding: 50px 20px;
    text-align: center;

    .projects-title {
        padding: 0.5em 0 0.3em;
        color: $colorText;
        // font-family: "Oswald", sans-serif;
        // font-size: 5em;
        font-size: 2.5em;
        text-transform: uppercase;
    }

    .projects-subtitle {
        width: 85%;
        margin: 0 auto;
        padding: 0.5em 0 1.5em;
        color: $colorText;
        font-size: 1em;
    }
    
    .projects-list {
        // display: flex;
        // justify-content: space-around;
        // flex-wrap: wrap;
        display: block;
        width: 100%;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 0 5em;

        .project-item {
            display: block;
            width: 100%;
            // margin: 30px auto 0;
            margin: 0 auto;
            padding: 0 0 2em;
        
            position: relative;
            // box-shadow: 0 0 10px rgba($light, 0.1);
            border-radius: 4px;
            overflow: hidden;

            a {
                text-decoration: none;
                font-weight: 600;
            }

            .contain-title {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
                width: 95%;
                margin: 0 auto;
                padding: 0.5em 0 0;
                // padding: 0.5em 1em 1em;

                .item-title, .item-date {
                    color: $colorText;
                    font-weight: 500;
                    text-align: left;
                }

                .item-title {
                    font-size: 1.6em;
                    line-height: 1.2em;
                }

                .item-date {
                    font-size: 0.8em;
                    padding: 1em 0 0.5em;
                }
            }

            .item-description {
                display: block;
                width: 95%;
                margin: 0 auto;

                padding: 1em 0 0.8em;
                font-size: 0.9em;
                font-weight: 300;
                line-height: 1.2em;
                color: $colorTextAlt;

                text-align: left;
            }
    
            img {
                max-width: 100%;
                height: auto;
                // border-radius: 5px;
                aspect-ratio: 16/9;
            }

            .item-categories {
                width: fit-content;
                // margin: 1em auto 0;
                margin: 0 0 1em 2.5%;
                padding: 1em 1em;
                border-radius: 100px;
            
                font-size: 0.7em;
                color: $colorTextAlt;
            
                background: rgba($gray, 0.5);
            }
        }
    }
}

@media (min-width: 768px) {
    .projects {
        padding: $desktopMargin 0 0;
        
        .projects-title {
            padding: .2em 0 0.2em;
            // font-size: 10em;
            font-size: 6em;
        }

        .projects-subtitle {
            font-size: 1.2em;
        }

        .projects-list {
                display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                padding: 0 0 10em;

                .project-item {
                    width: 48%;
                    max-width: $maxWidth;
                    padding: 0 0 3em;
                    margin: 0 auto;

                    .contain-title {
                        width: 100%;
                        padding: 1em 0 0;

                        .item-title {
                            font-size: 2em;
                        }

                        .item-date {
                            font-size: 1em;
                            padding: 10px 0 0 0;
                        }

                    }
                    
                    .item-description {
                        // display: none;
                        width: 100%;
                        font-size: 0.2em;
                        opacity: 0;

                        transition: .3s;
                    }

                    img {
                        border-radius: 6px;
                        transition: .3s;
                    }
                    
                    .item-categories {
                        margin: 0.5em 0 1em;
                        font-size: .9em;
                    }

                    &:hover {
                        .item-description {
                            display: block;
                            font-size: 1em;
                            opacity: 1;
                        }

                        img {
                            transform: scale(0.9);
                            margin: -2% auto 0;
                            // opacity: 0.6;
                        }
                    }
                }
            }
    }
}

@media (min-width: 1300px) {
    .projects .projects-list .project-item {
        margin: initial;
    }
}



