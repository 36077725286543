@import "normalize", "meyer", "basevalues";

.project-box {
    display: flex;
    flex-direction: column;
    max-width: $maxWidth;
    margin: 0 auto;
}

.syllabus-container {
    display: none;
    // color: red;
}

.project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // max-width: 805px;
    position: relative;
    // max-width: $maxWidth;

    .project-header {
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: 0 auto;

        .title-project {
            width: 100%;
            font-size: 2em;
            color: $colorText;
            text-align: left;
        }

        .description-project {
            width: 100%;
            font-size: 1em;
            line-height: 1.2em;
            padding: 0.5em 0;
            color: $colorTextAlt;
            font-weight: 100;
            text-align: left;
        }

        .section-project {
            width: 100%;
            padding: 0 0 0.5em;

            h5, p {
                text-align: left;
            }

            h5 {
                padding: 0 0 0.25em;
                color: $colorTextAltGray;
                font-weight: 400;
            }

            p {
                font-size: 1em;
                line-height: 1.2em;
                color: $colorText;
                font-weight: 400;
            }
        }
    }

    .cover-image-pro {
        width: 100%;
    }
    
    .project-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        width: 100%;
        padding-bottom: 2em;

        // =========== New COMPO =======================

        .element-block, .element-compact-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1em 0;
            width: 100%;
        }

        .title-elem, .subtitle-elem, .paragraph-elem, .p-elem-contrast {
            display: block;
            width: 95%;
            margin: 0 auto;
            padding: 0 0 0.5em;

            font-size: 1.4em;
            text-align: left;
            color: $colorText;
        }

        .subtitle-elem {
            // display: block;
            // width: 95%;
            // margin: 0 auto;
            // padding: 0 0 0.5em;
        
            font-size: 1em;
            text-align: left;
            color: $colorTextAltGray;
        }

        .paragraph-elem, .p-elem-contrast {
            // width: 95%;
            // margin: 0 auto;

            font-size: 1em;
            line-height: 1.2em;
            font-weight: 100;
            text-align: left;
            color: $colorTextAlt;

            a {
                color: $colorTextAlt;
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;
            }

            span {
                font-weight: 600;
            }
        }

        .p-elem-contrast {
            text-align: left;
            color: $colorText;
            font-weight: 600;
        }

        .horizontal-image, .vertical-image, .tercial-image {
            width: 100%;
            margin: 0 auto 2.5%;
        }

        .vertical-image {
            width: 75%;
            // margin: 0 auto 2.5%;
        }

        .tercial-image {
            width: 45%;
        }

        .divider-elem {
            display: block;
            width: 90%;
            height: 2px;
            margin: 10px auto;

            background: $colorText;
            border-radius: 50px;
        }
        
        // =========== End New COMPO ===================

        // .split-block {
        //     width: 100%;
        // }

        // .multi-pic-block {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     width: 100%;

        //     .multi-image {
        //         width: 100%;
        //         margin: 0 auto 0.5em;
        //     }
        // }

        // .title-section, .subtitle-section, .sub-subtitle-section{
        //     display: block;
        //     width: 100%;
        //     margin: 0 auto;
        //     padding: 1em 0;

        //     font-size: 2em;
        //     text-align: center;
        //     color: $colorText;
        // }

        // .subtitle-section {
        //     font-size: 1.5em;
        // }

        // .sub-subtitle-section {
        //     padding: 1em 0 0.5em;
        //     font-size: 1.2em;
        // }
        
        // .paragraphs-container {
        //     display: flex;
        //     flex-direction: column;
        //     width: 100%;
        //     padding: 0.25em 0;

        //     .header-p {
        //         display: block;
        //         width: 100%;
        //         margin: 0 auto;
        //         padding: 0.5em 0;
            
        //         font-size: 1em;
        //         text-align: center;
        //         color: $colorText;
        //     }

        //     .regular-p, .single-p{
        //         width: 95%;
        //         margin: 0 auto;
        //         padding: 0.5em 0;
    
        //         font-size: 1em;
        //         line-height: 1.2em;
        //         font-weight: 100;
        //         text-align: left;
        //         color: $colorTextAlt;
        //     }
        // }

        .slick-slider {
            // width: 85%;
            width: 100%;
            // margin: 0 auto 2.5em;
            margin: 1em auto 2.5em;
        }

        .slick-prev, .slick-next {
            z-index: 50;
        }

        .slick-prev {
            left: 10px;
        }
        
        .slick-next {
            right: 10px;
        }
    }
}

@media (min-width: 768px) {
    .project-box {
        display: flex;
        flex-direction: row;
        padding: $desktopMargin 0 0;
    }

    .syllabus-container {
        display: block;
        width: 28%;
        padding: 0.5em 2em 0 2em;
        position: fixed;
        
        .syllabus-element {
            width: 100%;
            text-align: left;

            color: rgba($colorText, 0.5);
            font-size: 0.85em;
            padding: 0.5em 0;
            cursor: pointer;
        }

        .s-e-current {
            color: $colorText;            
        }
    }

    .project-container {
        width: 75%;
        margin: 0 0 0 25%;

        .project-header {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            padding: 0 0 1em;

            .section-project {
                width: 30%;
            }
        }

        .project-main {
            flex-direction: row;
            justify-content: space-between;

            // =========== New COMPO =======================

            .element-compact-block {
                width: 50%;
                padding: 0 0 1em;
            }

            .title-elem, .subtitle-elem, .paragraph-elem,
            .p-elem-contrast {
                // display: block;
                width: 85%;
                // margin: 0 auto;
                // padding: 0 0 0.5em;
            }

            .vertical-image {
                width: 50%;
            }

            .tercial-image {
                width: 30%;
            }

            // =========== End New COMPO ===================
            
            .single-image {
                margin: 1em auto;
            }

            .split-block {
                width: 50%;
            }

            .multi-pic-block {
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;

                .multi-image {
                    width: 49%;
                    margin: 0 auto 1%;
                }
            }

            .paragraphs-container {
                display: flex;
                flex-direction: row;
                // align-items: center;
                align-items: flex-start;
                flex-wrap: wrap;
                width: 100%;
                
                .single-p {
                    width: 60%;
                    text-align: center;
                    // width: 95%;
                }

                .regular-p {
                    width: 95%;
                }
            }

            .slick-slider {
                width: 100%;
                margin: 0 auto 2.5em;
            }

            .slick-dots {
                bottom: -50px;

                li {
                    width: 40px;
                    height: 40px;
                }
            }

            // .slick-prev {
            //     left: -25px;
            // }

            // .slick-next {
            //     right: -25px;
            // }
        }
    }
}

@media (min-width: 1200px) {
    .syllabus-container {
        display: block;
        width: 25%;
        padding: 0.5em 2em 0 0;
        position: fixed;
    }
}

// ==============================================
.slick-prev:before,
.slick-next:before {
    color: rgba($colorTextAltGray, 0.5) !important;
}

.slick-dots li button:before {
    color: $colorText !important;
}