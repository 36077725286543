@import "normalize", "meyer", "basevalues";

.head-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 95%;
    max-width: $maxWidth;

    padding: 0.5em 0;

    // position: absolute;
    // top: 0;
    // left: 50%;
    // transform: translate(-50%, 0%);

    margin: 0 auto;
    z-index: 30;

    .logo-home {
        z-index: 40;

        .vr-logos {
            display: none;
            width: 50px;
            height: auto;
        }

        .vr-logo-open {
            display: block !important;
            transition: .3s;
        }
    }

    .menus-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    // Menu Mobile
    #menuContainer.menu-btn {
        display: block;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 60px;
        height: 60px;

        transform: scale(0.7);
        border-radius: 100%;
        cursor: pointer;
        transition: all .5s ease-in-out;
        // border: 3px solid $white;
        z-index: 100;

        background: $colorTextMenuMobile;
    }
}

.menu-btn {
    .menu-icons {
        display: none;
        width: 50px;
        height: auto;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // transition: 2s;
    }

    .menu-icons-open {
        display: block !important;
    }
}

.menu-options {
    display: block;
    width: 100%;
    height: 100vh;

    background: $colorResalte;

    transition: .8s;

    position: absolute;
    top: 0;
    left: -110%;
    transform: translate(0, 0);
    z-index: 20;

    .links-container, .links-container-desktop {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        width: 95%;
        max-width: $maxWidth;
        padding: 4em 0 0;

        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);

        opacity: 0;
        transition: .6s ease-in-out;

        .link-menu {
            font-size: 2.5em;
            font-weight: 600;

            margin: 0.25em 0;
            color: $colorText;
            list-style-type: none;

            transition: .25s ease-in-out;

            a {
                color: $colorTextMenuMobile;
                text-decoration: none;
                transition: .3s;

                &:hover {
                    color: $colorTextMenuMobileAlt;
                    // transition: .1s ease-in-out;
                }
            }
        }

        // .selected {
        //     color: $colorText;
        //     border-bottom: 4px solid $white;
        //     transition: .25s ease-in-out;
        // }

        // .l-m-m-active {
        //     color: $colorText;
        // }
    }

    .menu-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        max-width: $maxWidth;

        position: absolute;
        transform: translate(-50%, 0%);
        bottom: 7%;
        left: 50%;

        .menu-footer-container-links {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        p,
        a {
            display: block;
            width: 100%;

            font-size: 1em;
            font-weight: 400;
            text-align: left;
            color: $colorTextMenuMobile;

            margin: 0.4em auto;

        }

        p {
            margin: 1em auto;
        }

        a {
            text-decoration: underline;
            text-align: left;
            transition: .3s;

            &:hover {
                color: $colorTextMenuMobileAlt;
            }
        }
    }
}

.menu-mobile-open {
    left: 0%;

    .links-container, .links-container-desktop {
        // top: 50%;
        opacity: 1;
        transition: .6s ease-in-out;
    }
}

.menu-options-desktop {
    display: none;
}

@media (min-width: 768px) {
    .head-container {
        width: 90%;
        padding: 0.25em 0.8em;

        position: fixed;
        top: 1%;
        left: 50%;
        transform: translateX(-50%);

        // background: rgba($black, 0.3);
        // box-shadow: 0 0 14px $black;
        // backdrop-filter: blur(3px);
        // border-radius: 40px;

        transition: 0.5s;
    }

    .head-container-compact {
        width: 60%;
        max-width: 500px;

        background: rgba($black, 0.3);
        box-shadow: 0 0 14px $black;
        backdrop-filter: blur(3px);
        border-radius: 40px;
    }

    .head-container .menus-container {
        display: none;
    }

    .menu-options {
        display: none;
    }

    .menu-options-desktop {
        display: block;
        // background: rgba($black, 0.3);
        // box-shadow: 0 0 14px $black;
        // backdrop-filter: blur(3px);
        // border-radius: 40px;
        // padding: 0.5em;

        .links-container, .links-container-desktop {
            display: flex;
            flex-direction: row;
            align-items: center;

            list-style-type: none;

            .link-menu {
                padding: 0 0 0 1.2em;

                a {
                    color: $colorHeaderText;
                    text-decoration: none;

                    &:hover {
                        color: $colorHeaderTextAlt;
                    }
                }
            }

            .link-email {
                display: block;
                width: 100px;
                margin: 0 auto;
                padding: 0.6em 0;

                color: $light;
                border: 2px solid $light;
                border-radius: 8px;
                text-decoration: none;
                position: relative;
            }

            .btn-view-allpro-txt {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100px;
                // height: 48px;
                height: 40px;
                position: relative;
                margin: 0 auto;
                padding: 0 0.5em 0 1em;
                // padding: 6px 0px;
                // padding: 12px 50px;
                transition: all .2s ease;
                text-decoration: none;
                color: $colorText;
                border: 2px solid $colorText;
                border-radius: 50px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    border-radius: 8px;
                    // background: rgba($colorResalte, .5);
                    // background: rgba($colorResalte, 1);
                    width: 100%;
                    // width: 78px;
                    // height: 48px;
                    height: 40px;
                    transition: all .3s ease;
                }

                .container-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                span {
                    position: relative;
                    font-size: 16px;
                    line-height: 18px;
                    // font-weight: 900;
                    font-weight: 400;
                    // text-transform: uppercase;
                    vertical-align: middle;
                }

                svg {
                    position: relative;
                    top: 0;
                    margin-left: 10px;
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke: $colorText;
                    stroke-width: 2;
                    // transform: translateX(-5px) rotate(0deg);
                    transform: translateX(0) rotate(45deg);
                    transition: all .3s ease;
                }

                &:hover {
                    &:before {
                        // width: 100%;
                        width: 58px;
                        // background: rgba($colorResalte, 1);
                        // background: rgba($colorResalte, .5);
                    }

                    svg {
                        transform: translateX(-5px) rotate(0deg);
                        // transform: translateX(0) rotate(45deg);
                    }
                }

                // &:active {
                //     transform: scale(.96);
                // }
            }
        }
    }
}

@media (orientation: landscape) {
    .menu-mobile-open .links-container,
    .menu-mobile-open .links-container-desktop {
        padding: 2em 0 0;

        .link-menu {
            font-size: 1.4em;
            padding: 0.4em 0;
            margin: 0;
        }
    }
}

