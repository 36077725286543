@import "normalize", "meyer", "basevalues";

.hero-home {
    display: block;
    position: relative;
    background: url("../assets/Home_Pic_Small.webp");
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;

    // padding: 50px 20px;
    text-align: center;
    min-height: 80vh;

    overflow: hidden;

    left: 100%;
    transition: 0.7s ease-in-out;
}

.hero-home-activated {
    left: 0%;
}

.gradient-over {
    display: block;
    width: 100%;
    height: 102%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    // background: linear-gradient(160deg, $black, rgba(0, 0, 0, 0), $black);
    background: linear-gradient(160deg, #0A0A0A -14%, rgba(0, 0, 0, 0), #0A0A0A 114%);
    opacity: 1;
}

.hero-home-content {
    display: block;
    width: 90%;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    z-index: 10;

    opacity: 0;
    transition: 0.5s;

    h1 {
        display: block;
        color: $colorHeroText;
        font-size: 3.5em;
        font-weight: 700;

        text-transform: uppercase;
    }

    h3 {
        display: block;
        padding: 0 0 0.5em;

        color: $colorHeroTextAlt;
        font-size: 1.5em;
        font-weight: 500;

        text-transform: uppercase;
    }

    p {
        // margin-top: 20px;
        max-width: 450px;
        margin: 0 auto;

        padding: 0.5em 0 1em;
        font-size: 1.1em;
        line-height: 1.2em;
        color: $colorHeroTextAlt;
    }    
}

.hero-home-content-activated {
    opacity: 1;
}

@media (orientation: landscape) {
    .hero-home {
        background: url("../assets/Home_Pic.webp");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .hero-home-content {
        width: 50%;
        top: 50%;
        left: 5%;
        transform: translate(0%, -50%);
        text-shadow: 0 0 10px rgba($black, .8);

        h1 {
            font-size: 3.5em;
        }

        p {
            font-size: 0.85em;
        }
    }
}

@media (min-width: 768px) {
    .hero-home-content {
        top: 45%;

        h1 {
            font-size: 4em;
        }

        h3 {
            padding: 0;
            font-size: 2em;
        }
    }
}

@media (min-width: 1024px) {
    .hero-home-content {
        h1 {
            font-size: 5em;
        }
    }
}

@media (min-width: 1300px) {
    .hero-home {
        background-position: top center;
    }
}